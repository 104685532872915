.Horizontal {
    text-align: center;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.Vertical{
}

.AccountComponent {
    background-color: #282c34;
    float: left;
}

.InteractiveComponent {
    background-color: #282c34;
    width: 70%;
}

.ChatBoxComponent {
    background-color: #f0f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 99vh;
    min-width: 300px;
    padding: 0.5vh;
}

.TalkJs {
    background-color: #f0f2f2;
    width: 100%;
    height: 91vh;
}

.EmojiBar {
    width: 100%;
    height: 4vh;
    display: flex;
    flex-direction: row;
    min-height: 25px;
}

.Emoji {
    width: 100%;
    height: 100%;
    font-size: 150%;
    padding: 0;
    border: none;
    background: none;
}